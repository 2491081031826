import Image from 'next/image';
import { Card, CardContent } from './ui/card';
import Link from 'next/link';

export function BlogCard({ post }) {
  const { title, excerpt, coverImage, slug, category, date } = post.fields;

  return (
    <Link href={`/blog/${slug}`}>
      <Card className="w-72 flex-shrink-0 hover:scale-105 transition-transform duration-200 h-96 relative overflow-hidden shadow-md hover:shadow-lg">
        {coverImage && coverImage.fields?.file?.url && (
          <Image
            src={`https:${coverImage.fields.file.url}`}
            alt={title}
            fill
            sizes="(max-width: 768px) 100vw, 288px"
            className="object-cover"
          />
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent"></div>
        <CardContent className="absolute inset-0 p-4 flex flex-col justify-end">
          {category && (
            <div className="text-xs text-white uppercase mb-2 absolute top-4 left-4 bg-black bg-opacity-50 px-2 py-1 rounded">
              {category}
            </div>
          )}
          <h3 className="font-semibold text-white mb-2">{title}</h3>
          <p className="text-sm text-gray-300 mb-4">{excerpt}</p>
          {date && (
            <div className="text-xs text-gray-400 mt-2">
              {new Date(date).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              })}
            </div>
          )}
        </CardContent>
      </Card>
    </Link>
  );
}