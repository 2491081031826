'use client';
import { useRef } from 'react';
import Link from 'next/link';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { BlogCard } from '@/components/BlogCard';

export function ScrollSection({ title, items, viewMoreLink, className = '' }) {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollRef.current;
    const scrollAmount = direction === 'left' ? -container.offsetWidth : container.offsetWidth;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <section className={`relative py-8 ${className}`}>
      <div className="flex justify-between items-center mb-4 px-4">
        <Link 
          href={viewMoreLink}
          className="text-2xl font-bold hover:text-gray-700 transition-colors"
        >
          {title}
        </Link>
        <Link
          href={viewMoreLink}
          className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
        >
          View More
        </Link>
      </div>
      
      <div className="group relative">
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-black/50 p-2 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity disabled:opacity-0"
          disabled={scrollRef.current?.scrollLeft === 0}
        >
          <ChevronLeft size={24} />
        </button>

        <div
          ref={scrollRef}
          className="flex space-x-4 overflow-x-scroll scrollbar-hide h-[28rem] py-4"
        >
          {items.map((item) => (
            <div key={item.sys.id} className="flex-shrink-0">
              <BlogCard post={item} />
            </div>
          ))}
        </div>

        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-black/50 p-2 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </section>
  );
}